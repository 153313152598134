import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: () => {
      return "/orders/administration";
    },
  },

  {
    path: "/management/users/all",
    name: "all-users",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "ACC"],
    },
    component: () => import("../views/sudo/users/All"),
  },
  {
    path: "/management/users/panel",
    name: "users-panel",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/users/panel"),
  },

  {
    path: "/management/users/businessOwner",
    name: "business-owner-users",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () => import("../views/sudo/users/BusinessOwner"),
  },
  {
    path: "/management/users/sidekick",
    name: "sidekick-users",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () => import("../views/sudo/users/SideKick"),
  },
  {
    path: "/management/users/cargo",
    name: "cargo-users",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () => import("../views/sudo/users/cargo"),
  },
  {
    path: "/management/users/experience",
    name: "experience-users",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/users/experience"),
  },

  {
    path: "/management/users/corporativeCoupons",
    name: "corporate-coupons-users",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/users/corporativeCoupons"),
  },

  {
    path: "/management/businesses",
    name: "sudo-businesses",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC", "ACC"],
    },
    component: () => import("../views/sudo/businesses/businesses"),
  },

  {
    path: "/orders/onboarding",
    name: "sudo-onboarding",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "AF", "admin"],
    },
    component: () => import("../views/sudo/onboarding/list"),
  },

  {
    path: "/management/corporative",
    name: "corporative",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () =>
      import("../views/sudo/corporative-errands/corporative-errands"),
  },
  {
    path: "/management/joinus",
    name: "joius",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC", "admin"],
    },
    component: () => import("../views/sudo/joinus/list"),
  },
  {
    path: "/management/corporate-coupons",
    name: "corporate-coupons",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () =>
      import("../views/sudo/corporate-coupons/corporate-coupons"),
  },

  {
    path: "/management/menuhn/delivery",
    name: "menu-delivery",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/menuhn/delivery"),
  },

  {
    path: "*",
    name: "not-found",
    meta: {
      appFrame: true,
      navBar: true,
    },
    component: () => import("../views/notFound.vue"),
  },
  {
    path: "/orders/administration",
    name: "c19",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "AF", "EFCM", "AC", "ACC", "AM", "driver"],
    },
    component: () => import("../views/C19.vue"),
  },
  {
    path: "/ordersTheatre",
    name: "treatre",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "AF", "EFCM", "AC", "ACC", "AM", "businessOwner"],
    },
    component: () => import("../views/cine/Orders-theatre.vue"),
  },
  {
    path: "/cine/user",
    name: "treatre",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () => import("../views/cine/adminUser.vue"),
  },
  {
    path: "/orderstickets",
    name: "treatre",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "AF", "EFCM", "AC", "ACC", "AM", "businessOwner"],
    },
    component: () => import("../views/cine/Orders-tickets.vue"),
  },
  {
    path: "/orders/validation",
    name: "order-validate",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "AF"],
    },
    component: () => import("../views/Order-validate.vue"),
  },
  {
    path: "/orders/scheduled",
    name: "order-scheduled",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "AF"],
    },
    component: () => import("../views/sudo/scheduled-orders/index.vue"),
  },
  {
    path: "/orders/help-request",
    name: "help-request",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "AF", "admin", "AC"],
    },
    component: () => import("../views/sudo/help-request/help-request"),
  },
  {
    path: "/orders/dropped-orders",
    name: "dropped-orders",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "AF", "admin", "ACC"],
    },
    component: () => import("../views/sudo/dropped-orders/orders"),
  },
  {
    path: "/orders",
    name: "orders",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["businessOwner"],
    },
    component: () => import("../views/Orders.vue"),
  },

  {
    path: "/business/orders",
    name: "business-orders",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo", "admin", "AF", "EFCM", "AC", "ACC"],
    },
    component: () => import("../views/Orders.vue"),
  },
  {
    path: "/business/general",
    name: "owner-general",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: [
        "sudo",
        "businessOwner",
        "ACC",
        "AC",
        "EFCM",
        "AF",
        "admin",
      ],
    },
    component: () => import("../views/business/General.vue"),
  },
  {
    path: "/business/api",
    name: "business-api",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/business/api.vue"),
  },
  {
    path: "/business/map",
    name: "owner-map",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["businessOwner", "sudo", "AF", "AC", "EFCM", "admin"],
    },
    component: () => import("../views/business/Map.vue"),
  },
  {
    path: "/business/schedule",
    name: "owner-schedule",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner", "AF", "AC", "EFCM", "admin"],
    },
    component: () => import("../views/business/Schedule.vue"),
  },
  {
    path: "/business/sections",
    name: "owner-sections",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner", "AF", "AC", "EFCM", "admin"],
    },
    component: () => import("../views/business/sections/Sections"),
  },
  {
    path: "/business/products",
    name: "owner-products",
    props: true,
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner", "AF", "AC", "EFCM", "admin"],
    },
    component: () => import("../views/business/products/products"),
  },
  {
    path: "/business/addons",
    name: "owner-addons",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner", "AF", "AC", "EFCM", "admin"],
    },
    component: () => import("../views/business/products-addons/addons"),
  },
  {
    path: "/business/discounts",
    name: "discounts",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner", "AC", "EFCM", "admin", "AF"],
    },
    component: () => import("../views/business/discounts/discounts"),
  },
  {
    path: "/business/priceLists",
    name: "price-lists",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner", "AC", "EFCM", "admin", "AF"],
    },
    component: () => import("../views/business/price-lists/price-lists"),
  },
  {
    path: "/business/customers",
    name: "customers",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner", "AC", "EFCM", "admin", "AF"],
    },
    component: () => import("../views/business/customers/customers"),
  },
  {
    path: "/business/bankAccounts",
    name: "bank-accounts",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: [
        "sudo",
        "businessOwner",
        "ACC",
        "AC",
        "EFCM",
        "admin",
        "AF",
      ],
    },
    component: () => import("../views/business/bank-accounts/bank-accounts"),
  },

  {
    path: "/business/products-update",
    name: "business-products-update",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["businessOwner"],
    },
    component: () => import("../views/business/products-update/excel"),
  },
  {
    path: "/select-business",
    name: "select-business",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["businessOwner"],
    },
    component: () => import("../views/SelectBusiness"),
  },

  {
    path: "/settings/countries",
    name: "countries",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () => import("../views/sudo/countries/countries"),
  },
  {
    path: "/settings/cities",
    name: "cities",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () => import("../views/sudo/cities/cities"),
  },
  {
    path: "/settings/reasons-cancellation",
    name: "reasons-cancellation",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/reasons-cancellation/list"),
  },
  {
    path: "/settings/reasons-coupons",
    name: "reasons-coupons",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/reasons-coupons/list"),
  },
  {
    path: "/settings/banks",
    name: "banks",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/banks/list"),
  },
  {
    path: "/settings/distance-rates",
    name: "distance-rates",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/distance-rates/list"),
  },
  {
    path: "/operations/massive-operations",
    name: "massive-operations",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () =>
      import("../views/sudo/massive-operations/massive-operations"),
  },

  {
    path: "/operations/massive-operations-2",
    name: "massive-operations",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () =>
      import("../views/sudo/massive-operations-2/massive-operations"),
  },
  {
    path: "/settings/categories",
    name: "categories",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC", "admin"],
    },
    component: () => import("../views/sudo/categories/categories"),
  },
  // {
  //   path: "/promos/giveaways",
  //   name: "giveaways",
  //   meta: {
  //     appFrame: true,
  //     navBar: true,
  //     allowedRoles: ["sudo"],
  //   },
  //   component: () => import("../views/sudo/giveaways/giveaways"),
  // },
  // {
  //   path: "/management/users/all",
  //   name: "all-users",
  //   meta: {
  //     appFrame: true,
  //     navBar: true,
  //     allowedRoles: ["sudo"],
  //   },
  //   component: () => import("../views/sudo/users/Sudo"),
  // },
  {
    path: "/reversals",
    name: "reversals",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/reversals/reversals"),
  },
  {
    path: "/card-payments",
    name: "payments",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/card-payments/card-payments"),
  },
  {
    path: "/operations/cards-validation",
    name: "cardsValidation",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "AF"],
    },
    component: () => import("../views/sudo/cards-validation/cards-validation"),
  },
  {
    path: "/validate-zone",
    name: "zoneValidation",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/zone-validation/zone-validation"),
  },
  {
    path: "/ads",
    name: "ads",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/ads/ads"),
  },
  {
    path: "/promos",
    name: "promos",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/promos/promos"),
  },

  {
    path: "/operations/quick-settings",
    name: "quick-settings",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/quick-settings/QuickSettings"),
  },
  {
    path: "/settings/payment-gateways",
    name: "payment-gateways",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/payment-gateways/payment-gateways"),
  },

  {
    path: "/reports/payment",
    name: "payment-reports",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "ACC", "admin", "AC"],
    },
    component: () => import("../views/sudo/payment-reports/payment-reports"),
  },
  {
    path: "/reports/others",
    name: "other-reports",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "ACC", "admin", "AC", "EFCM"],
    },
    component: () => import("../views/sudo/reports/others"),
  },

  {
    path: "/orders-reports",
    name: "orders-reports",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/orders-reports/orders-reports"),
  },

  {
    path: "/settings/zones",
    name: "zones",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/zones/Zones"),
  },

  {
    path: "/messaging/push-notifications",
    name: "push-notifications",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () =>
      import("../views/sudo/push-notifications/push-notifications"),
  },
  {
    path: "/security/otp",
    name: "messaging-otp",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/otp/list"),
  },
  {
    path: "/messaging/in-app-messaging",
    name: "in-app-messaging",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/in-app-messaging/list"),
  },
  {
    path: "/security/ledger",
    name: "ledger",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/ledger/Ledger"),
  },
  {
    path: "/reports/active-promotions",
    name: "promotions-details",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "ACC"],
    },
    component: () => import("../views/sudo/promotions-details/list"),
  },

  // {
  //   path: "/promos/coupons",
  //   name: "coupons3",
  //   meta: {
  //     transitionName: "fade",
  //     appFrame: true,
  //     navBar: true,
  //     allowedRoles: ["sudo"],
  //   },
  //   component: () => import("../views/sudo/coupons3/Coupons"),
  // },
  {
    path: "/promos/coupons4",
    name: "coupons4",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/coupons4/Coupons"),
  },
  {
    path: "/promos/coupons-bin",
    name: "coupons-bin",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/coupons-bins/coupons"),
  },

  {
    path: "/promos/roulette",
    name: "roulette",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/roulette/list"),
  },

  {
    path: "/promos/active",
    name: "active-promotions",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () => import("../views/sudo/promotions-details/list"),
  },

  {
    path: "/orders/messages",
    name: "messages",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "AF"],
    },
    component: () => import("../views/sudo/messages/Messages"),
  },

  {
    path: "/cargo/general",
    name: "corporative-general",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () => import("../views/sudo/corporative-errands/menu/general"),
  },

  {
    path: "/cargo/errands",
    name: "corporative-errands",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () => import("../views/sudo/corporative-errands/menu/errands/list"),
  },

  {
    path: "/cargo/webhook",
    name: "corporative-webhook",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () => import("../views/sudo/corporative-errands/menu/webhook"),
  },

  {
    path: "/cargo/map",
    name: "corporative-map",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () => import("../views/sudo/corporative-errands/menu/map"),
  },

  {
    path: "/cargo/starting",
    name: "corporative-starting",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () =>
      import(
        "../views/sudo/corporative-errands/menu/starting-points/starting-points"
      ),
  },

  {
    path: "/cargo/destination",
    name: "corporative-destination",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () =>
      import(
        "../views/sudo/corporative-errands/menu/destination-points/destination-points"
      ),
  },

  {
    path: "/cargo/points",
    name: "corporative-points",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () =>
      import(
        "../views/sudo/corporative-errands/menu/points-allowed/points-allowed"
      ),
  },

  {
    path: "/cargo/user",
    name: "corporative-user",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () =>
      import("../views/sudo/corporative-errands/menu/ocho-user/ocho-user"),
  },
  {
    path: "/cargo/contracts",
    name: "corporative-contracts",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () =>
      import("../views/sudo/corporative-errands/menu/contracts/contracts"),
  },
  {
    path: "/cargo/api",
    name: "cargo-api",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "admin", "AC"],
    },
    component: () =>
      import("../views/sudo/corporative-errands/menu/api-keys/key"),
  },

  //CORPORATE COUPONS PAGE
  {
    path: "/coupons/general",
    name: "corporative-coupons-general",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "corporateCoupons"],
    },
    component: () => import("../views/sudo/corporate-coupons/menu/general"),
  },
  {
    path: "/coupons/amounts",
    name: "corporative-coupons-amounts",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "corporateCoupons"],
    },
    component: () =>
      import("../views/sudo/corporate-coupons/menu/amounts/amounts"),
  },

  {
    path: "/coupons/users",
    name: "corporative-coupons-users",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "corporateCoupons"],
    },
    component: () =>
      import("../views/sudo/corporate-coupons/menu/user-lists/users"),
  },

  {
    path: "/coupons/master",
    name: "corporative-coupons-master",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () =>
      import("../views/sudo/corporate-coupons/menu/ocho-user/ocho-user"),
  },

  {
    path: "/coupons/list",
    name: "corporative-coupons-coupons",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "corporateCoupons"],
    },
    component: () =>
      import("../views/sudo/corporate-coupons/menu/coupons/coupons"),
  },

  //superSAC pages
  {
    path: "/rating/drivers",
    name: "bikers-rating",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "AF", "EFCM", "admin", "AC", "driver"],
    },
    component: () => import("../views/SAC/bikers-rating/Bikers-rating"),
  },
  {
    path: "/rating/businesses",
    name: "businesses-ratings",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "AF", "EFCM", "admin", "AC", "driver"],
    },
    component: () =>
      import("../views/SAC/businesses-ratings/Businesses-ratings"),
  },
  {
    path: "/promos/all",
    name: "promotions",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () => import("../views/sudo/promotions/promotions"),
  },

  {
    path: "/settings/tags",
    name: "tags",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM"],
    },
    component: () => import("../views/sudo/tags/tags"),
  },

  {
    path: "/security/access",
    name: "accessLogs",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/accessLogs/logs"),
  },

  {
    path: "/settings/ocho-pay",
    name: "ocho-pay",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () => import("../views/sudo/ocho-pay/pay"),
  },

  {
    path: "/management/menuhn/delivery-zones",
    name: "delivery-zones",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/sudo/delivery-zones/zones"),
  },

  {
    path: "/login",
    name: "login",
    meta: {
      transitionName: "zoom",
      appFrame: false,
      navBar: false,
    },
    component: () => import("../views/Login.vue"),
  },

  {
    path: "/profile",
    name: "profile",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo"],
    },
    component: () => import("../views/settings/profile.vue"),
  },

  //cashier

  {
    path: "/cashier/report",
    name: "cashier-report",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "EFCM", "AC"],
    },
    component: () => import("../views/cashier/reports/orders"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
