import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/app-check";

let env = process.env.NODE_ENV;

var prodConfig = {
  apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
  authDomain: "el-ocho.firebaseapp.com",
  databaseURL: "https://el-ocho.firebaseio.com",
  projectId: "el-ocho",
  storageBucket: "el-ocho.appspot.com",
  messagingSenderId: "770366666625",
  appId: "1:770366666625:web:f6d79634f54385a09b1a80",
  measurementId: "G-2PBQGFMTGH",
};

const devConfig = {
  apiKey: "AIzaSyBKuyr82-r86E5B2UWtPmTSR9xh8O-T1_M",
  authDomain: "delivery-dev-64a57.firebaseapp.com",
  databaseURL: "https://delivery-dev-64a57-default-rtdb.firebaseio.com",
  projectId: "delivery-dev-64a57",
  storageBucket: "delivery-dev-64a57.appspot.com",
  messagingSenderId: "124223460151",
  appId: "1:124223460151:web:2c1358444340588a68c0b2",
  measurementId: "G-KS9M04S0VQ"
};

firebase.initializeApp(env == "production" ? prodConfig : devConfig);

firebase.auth().languageCode = "es";

// if (window.origin.includes("localhost")) {
//   self.FIREBASE_APPCHECK_DEBUG_TOKEN =
//     process.env.VUE_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
//   firebase.functions().useEmulator("localhost", 5001);
// }

if (process.env.VUE_APP_APP_CHECK) {
  // const appCheck = firebase.appCheck();
  // appCheck.activate(process.env.VUE_APP_APP_CHECK, true);
}

export const db = firebase.firestore();
export const fb = firebase;
