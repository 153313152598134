<template>
  <v-dialog persistent v-model="weatherDialog" max-width="800px">
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Estado del clima</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          small
          color="primary"
          fab
          absolute
          @click="weatherDialog = false"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <!-- <v-snackbar top :timeout="3000" v-model="snackbar">{{
          snackbarText
        }}</v-snackbar> -->
      
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-data-table :headers="headers" :items="getCities">
                <template v-slot:[`item.weather`]="{ item }">
                  <v-chip v-if="item.weather && item.weather.length" pill>
                    <v-avatar left>
                      <v-img
                        :src="`https://openweathermap.org/img/w/${item.weather[0].icon}.png`"
                      ></v-img>
                    </v-avatar>
                    <span style="text-transform: capitalize">
                      {{ item.weather[0].description }}
                    </span>
                  </v-chip>

                  <v-chip v-else color="gray"> Desconocido </v-chip>
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <span style="color: red">
                    {{
                      item.isRaining
                        ? "Activar Clima Duro desde la pantalla de categorías."
                        : ""
                    }}</span
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "weather",
  data() {
    return {
      weatherDialog: false,
      headers: [
        {
          text: "Ciudad",
          value: "name",
          active: true,
        },
        {
          text: "Estado del Clima",
          value: "weather",
          active: true,
        },
        {
          text: "",
          value: "action",
          active: true,
        },
      ],
      //   cities: [],
      localCities: [],
    };
  },

  methods: {
    ...mapActions(["updateCities"]),
  },

  computed: {
    ...mapState(["cities", "allowNotifications"]),
    getCities() {
      return this.cities.filter((item) => item.weather);
    },
  },

  watch: {
    localCities(e) {
      if (!e.length) {
        return;
      }

      let isRaining = this.localCities.filter((item) => item.isRaining);

      let cities = this.localCities.map((item) => {
        return {
          name: item.name,
          isRaining: item.isRaining,
          weather: item.weather,
          id: item[".key"],
        };
      });

      if (!this.cities.length) {
        if (isRaining) {
          isRaining.forEach((i) => {
            if (this.allowNotifications) {
              this.$notify({
                group: "foo",
                title: `Activar Clima Duro`,
                text: `Diríjase a las categorías de ${i.name} y active el cargo por clima duro. `,
                duration: -1,

                data: {
                  image: `https://openweathermap.org/img/w/${i.weather[0].icon}.png`,
                },
              });
            }
          });
        }
        this.updateCities(cities);
      } else {
        this.localCities.forEach((city) => {
          let search = this.cities.find((item) => item.id == city[".key"]);

          if (search && search.isRaining != city.isRaining) {
            if (this.allowNotifications) {
              this.$notify({
                group: "foo",
                title: ` ${
                  city.isRaining ? "Activar" : "Desactivar"
                }  Clima Duro`,
                text: `Diríjase a las categorías de ${search.name} y ${
                  city.isRaining ? "active" : "desactive"
                }  el cargo por clima duro. `,
                duration: -1,

                data: {
                  image: `https://openweathermap.org/img/w/${city.weather[0].icon}.png`,
                },
              });
            }
          }
        });

        this.updateCities(cities);
      }
    },
  },

  mounted() {
    this.$binding(
      "localCities",
      db.collection("cities").where("active", "==", true)
    );
  },
};
</script>