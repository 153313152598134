<template>
  <v-dialog persistent v-model="modal" max-width="650px">
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          {{
            this.type ? "Nuevo reporte de tiempos" : "Nuevo reporte de ventas"
          }}
        </span>

        <v-btn
          style="right: 10px; top: 10px"
          icon
          v-if="!saving"
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pa-3">
          <v-snackbar top :timeout="3000" v-model="snackbar">
            {{ snackbarText }}
          </v-snackbar>
          <!-- <v-overlay class="loading-center" :value="saving">
            <lottie
              :options="defaultOptions"
              :height="300"
              :width="400"
              v-on:animCreated="handleAnimation"
            />
          </v-overlay> -->
          <v-row>
            <!-- <v-col cols="12" sm="12" md="12"> -->
            <!-- <v-text-field
                v-model.trim="code"
                placeholder="Código del reporte"
                rounded
                hint="Dejar vacio para que el sistema asigne un código automático."
                outlined
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="12">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <input
                    class="control-input"
                    v-on="on"
                    type="text"
                    placeholder="Fecha inicia"
                    v-model="date[0]"
                  />
                </template>
                <v-date-picker v-model="date[0]" @input="menu1 = false">
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="12">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <input
                    class="control-input"
                    v-on="on"
                    type="text"
                    placeholder="Fecha final"
                    v-model="date[1]"
                  />
                </template>
                <v-date-picker @input="menu2 = false" v-model="date[1]">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                v-model="tagsToSearch"
                :items="searchableTags"
                label="Filtrar por estado"
                item-text="name"
                item-value="value"
                multiple
                aria-autocomplete="false"
                autocomplete="off"
                rounded
                chips
                persistent-hint
                hint="No seleccionar este filtro para obtener todas las órdenes"
                outlined
                clearable
              >
              </v-select>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="12">
              <p>Tasa de cambio del dolar:</p>
              <v-text-field rounded v-model.number="exchangeRate" outlined>
              </v-text-field>
            </v-col> -->
            <v-col cols="12">
              <v-btn
                @click="generateReport"
                class="save-btn mt-4"
                color="primary"
                :loading="saving"
                block
                min-height="55px"
              >
                Generar
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-row class="pa-0 ma-0 mt-4"> -->
          <!-- <v-col cols="12">
            <v-btn
              @click="generateReport"
              class="save-btn mt-4"
              color="primary"
              block
              min-height="50px"
            >
              Generar
            </v-btn>
          </v-col> -->
          <!-- </v-row> -->
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import axios from "axios";

export default {
  name: "new-report",
  props: ["type"],
  components: {
    lottie: Lottie,
  },
  mixins: [lottieSettings],
  data() {
    return {
      saving: false,
      snackbarText: "",
      snackbar: false,
      menu1: "",
      menu2: "",
      date: [],
      description: "",
      code: "",
      modal: true,
      exchangeRate: 0,
      tagsToSearch: [],
      searchableTags: [
        { value: "placed", name: "Colocadas", color: "lime darken-1" },
        { value: "accepted", name: "Aceptadas", color: "green" },
        { value: "enRoute", name: "Camino al comercio", color: "amber" },
        { value: "onDelivery", name: "Camino al cliente", color: "orange" },
        { value: "delivered", name: "Entregadas", color: "cyan" },
        { value: "completed", name: "Completadas", color: "blue" },
        { value: "canceled", name: "Canceladas por el cliente", color: "grey" },
        {
          value: "pendingValidation",
          name: "Pendientes de Validar",
          color: "purple",
        },
        {
          value: "rejected",
          name: "Canceladas por administración",
          color: "red",
        },
      ],
    };
  },
  // computed: {
  //   getSectionToken() {
  //     return new Promise((resolve, reject) => {
  //       fb.auth()
  //         .currentUser.getIdToken(true)
  //         .then((token) => {
  //           resolve(token);
  //         });
  //     });
  //   },
  // },
  methods: {
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    checkNotObject(data) {
      return typeof data.item !== "object";
    },

    async generateReport() {
      if (!this.date[0] || !this.date[1]) {
        this.snackbarText = "Debe completar las fechas";
        this.snackbar = true;
        return;
      }

      if (this.date[0] > this.date[1]) {
        this.snackbarText =
          "La fecha final debe ser mayor que la fecha inicial";
        this.snackbar = true;
        return;
      }

      this.saving = true;
      let token = await this.getSectionToken();

      let data = {
        startDate: this.date[0],
        endDate: this.date[1],
        orderStatus: this.tagsToSearch,
      };

      let functionName =
        this.type == "driverTimes"
          ? "httpReportsDriverTime"
          : "callableReportsFullOrders";

      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/${functionName}`,
          // `http://127.0.0.1:5001/el-ocho-dev/us-central1/${functionName}`,
          data,
          { headers: { Authorization: `Basic ${token}` }, responseType: "blob" }
        )
        .then((response) => {
          this.saving = false;
          this.$emit("cancel");

          const { data, headers } = response;
          const fileName = `Reporte ${this.date[0]} al ${this.date[1]}.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.saving = false;
          console.log(err.response);
        });
    },
    generateTimestamp() {
      return new Promise((resolve, reject) => {
        let date = this.date[0].split("-");
        this.coupon.redeemableFrom = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("07"),
            parseInt("00"),
            parseInt("00")
          )
        );
        date = this.date[1].split("-");
        this.coupon.redeemableUntil = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt("19"),
            parseInt("00"),
            parseInt("00")
          )
        );
        resolve(true);
      });
    },
  },
};
</script>
