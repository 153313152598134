import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "ochoTempAdmin",
  storage: window.localStorage,
  modules: [
    "ordersHeaders",
    "selectedBusiness",
    "selectedCorporative",
    "selectedCities",
    "menu",
    "cities",
    "allowNotifications",
  ],
});

export default new Vuex.Store({
  state: {
    search: "",
    searchTerm: "",
    darkmode: false,
    appFrame: false,
    ordersHeaders: [],
    navBar: false,
    user: {},
    userIsValid: true,
    visibleSearch: true,
    selectedBusiness: "",
    selectedCorporative: "",
    // selectedCity: [],
    selectedCities: [],
    menu: [],
    cities: [],
    allowNotifications: true,
  },
  getters: {
    userIsValid: (state) => state.userIsValid,
    userType: (state) => state.user.type,
  },
  mutations: {
    setUser(state, target) {
      state.user = target;

      if (state.user.type == "sudo" && state.user.businesses) {
        state.user.businesses = [];
      }
    },
    setBusinessesList(state, target) {
      state.businessesList = target;
    },
    setUserValidity(state, target) {
      state.userIsValid = target;
      setTimeout(() => {
        state.userIsValid = true;
      }, 3000);
    },
    setSearch(state, target) {
      state.search = target;
    },
    setOrderHeaders(state, target) {
      state.ordersHeaders = target;
    },
    setSearchTerm(state, target) {
      state.searchTerm = target;
    },
    setDarkMode(state, target) {
      state.darkmode = target;
    },
    setAppFrame(state, target) {
      state.appFrame = target;
    },
    setNavBar(state, target) {
      state.navBar = target;
    },
    setEnvironment(state, target) {
      state.environment = target;
    },
    setSelectedBusiness(state, target) {
      state.selectedBusiness = target;
    },
    setCorporative(state, target) {
      state.selectedCorporative = target;
    },

    setSelectedCities(state, target) {
      state.selectedCities = target;
    },
    setMenu(state, target) {
      state.menu = target;
    },

    setCities(state, target) {
      state.cities = target;
    },

    setNotifications(state, target) {
      state.allowNotifications = target;
    },
  },
  actions: {
    addBusiness: ({ commit, state }, business) => {
      commit("setSelectedBusiness", business);
    },
    addCorporative: ({ commit, state }, business) => {
      commit("setCorporative", business);
    },

    addMenu: ({ commit, state }, menu) => {
      commit("setMenu", menu);
    },

    updateCity: ({ commit, state }, cityId) => {
      commit("setSelectedCities", cityId);
    },

    updateCities: ({ commit, state }, cities) => {
      commit("setCities", cities);
    },
    showNotifications: ({ commit, state }, value) => {
      commit("setNotifications", value);
    },
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
